

import whatsapp from "../../assests/images/whatsapp.png";
import youtube from "../../assests/images/youtube.png";
import insta from "../../assests/images/insta.png";
import facebook from "../../assests/images/facebook.png";
import locate from "../../assests/images/locate.png";
import phone from "../../assests/images/phone.png";
import mail from "../../assests/images/mail.png";
import rimsLogo from "../../assests/images/rimslogo.png"
import { NavLink } from "react-router-dom";

const FooterPage = () => {
    return (
        <>
            <footer class="footer-section">
                <div class="container">
                    <div class="footer-content footer-cta pt-5 pb-5">
                        <div class="row foot-center">
                            <div class="col-xl-5 col-lg-5 mb-50">
                                <div class="footer-widget">
                                    <div class="footer-logo">
                                        <a href="index.html"><img src={rimsLogo} class="img-fluid" alt="logo" /></a>
                                    </div>
                                    <div class="footer-text">
                                        <p>Accruon Global Consultants <br/> #206, 2nd Floor, Money Chambers, KH Road, Double Rd, Shanti Nagar, Bengaluru, Karnataka 560027.
                                        </p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-5 mb-30">
                                <div class="footer-widget">
                                    <div class="footer-widget-heading">
                                        <h3>Inner pages</h3>
                                    </div>
                                    <ul>
                                        <li ><NavLink to="/">Home</NavLink></li>
                                        <li><NavLink to="/about" >About</NavLink></li>
                                        <li><NavLink to="/features" >Features</NavLink></li>
                                        {/* <li><NavLink to="/testimonials" >Testimonials</NavLink></li> */}
                                        <li><NavLink to="/contact-us" >Contact</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                                <div class="footer-widget">
                                    <div class="footer-widget-heading">
                                        <h3>Contacts</h3>
                                    </div>
                                    <div class="footer-text mb-25">
                                        <div class="foot-ic"><div class="foot-ic1"><img src={locate} /></div><div class="foot-add"> Accruon Global Consultants <br/> #206, 2nd Floor, Money Chambers, KH Road, Double Rd, Shanti Nagar, Bengaluru, Karnataka 560027.</div></div>
                                        <div class="foot-ic"><div class="foot-ic1"><img src={phone} /></div><div class="foot-add">+919388411292</div></div>
                                        <div class="foot-ic"><div class="foot-ic1"><img src={mail} /></div><div class="foot-add">rijas@rimstech.in</div></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright-area footer-cta">
                    <div class="container">
                        <div class="row foot-last">
                            <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                                <div class="copyright-text">
                                    <p>Copyright © 2025 RiMS</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        </>)
}
export default FooterPage;